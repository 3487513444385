import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { isEqual, cloneDeep } from 'lodash';
import {
	accountStrategyList,
	accountStrategyNew,
	accountStrategyRemoval,
	accountStrategyActivation,
	accountStrategyChange,
} from 'api/trading-api';
import { later } from 'lib/timers';
import { getTradesResultScore, getStrategyMaturity } from 'lib/strategy-trades-result-score';
import { REFRESH_TIME_ACCOUNTSTRATEGYDATA_SEC } from 'config/constants';

// REFRESH_TIME_ACCOUNTDATA_MS is used to ensure that accountdata also is up to date when changed from another computer
const REFRESH_TIME_ACCOUNTSTRATEGYDATA_MS = REFRESH_TIME_ACCOUNTSTRATEGYDATA_SEC * 1000;

const useAccountStrategies = () => {
	const [accountStrategies, setAccountStrategies] = useState([]);
	const [selectedAccountStrategy, setSelectedAccountStrategy] = useState();
	const accountStrategiesRef = useRef([]);
	const timeoutId = useRef();

	const startPolling = () => {
		stopPolling();
		timeoutId.current = later(loadData, 0, REFRESH_TIME_ACCOUNTSTRATEGYDATA_MS);
	};

	const stopPolling = () => {
		if (timeoutId.current) {
			timeoutId.current.cancel();
		}
	};

	useEffect(() => {
		return () => {
			stopPolling();
		};
	}, []);

	const loadData = async () => {
		try {
			const newAccountStrategies = await accountStrategyList({ showperformance: true, performancelimit: 20 });

			newAccountStrategies.forEach(newAccountStrategy => {
				// calculate the tradesresultscore
				newAccountStrategy.tradesresultscore = getTradesResultScore(newAccountStrategy.tradesresult);
				newAccountStrategy.maturity = getStrategyMaturity(newAccountStrategy.tradesresult);
			});

			if (!isEqual(newAccountStrategies, accountStrategiesRef.current)) {
				accountStrategiesRef.current = cloneDeep(newAccountStrategies);
				setAccountStrategies(newAccountStrategies);
			}
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const createAccountStrategy = async ({
		active,
		strategyid,
		type,
		symbol,
		exchange,
		network,
		leverage,
		percentamount,
	}) => {
		let result;
		try {
			result = await accountStrategyNew({
				strategyid,
				type,
				symbol,
				exchange,
				network,
				leverage,
				percentamount,
				active,
			});
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
		return result;
	};

	const activateAccountStrategy = async accountstrategyid => {
		try {
			await accountStrategyActivation({ accountstrategyid, active: true });
			loadData();
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const deactivateAccountStrategy = async accountstrategyid => {
		try {
			await accountStrategyActivation({ accountstrategyid, active: false });
			loadData();
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const changeAccountStrategy = async ({
		accountstrategyid,
		strategyid,
		type,
		symbol,
		exchange,
		network,
		leverage,
		percentamount,
	}) => {
		let result;
		try {
			result = await accountStrategyChange({
				accountstrategyid,
				strategyid,
				type,
				symbol,
				exchange,
				network,
				leverage,
				percentamount,
			});
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
		return result;
	};

	const deleteAccountStrategy = async accountstrategyid => {
		let result;
		try {
			await accountStrategyRemoval({ accountstrategyid });
			result = true;
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			result = false;
		}
		return result;
	};

	return {
		accountStrategies, // invoked by user
		createAccountStrategy, // invoked by user
		activateAccountStrategy, // by an admin
		deactivateAccountStrategy, // by an admin
		changeAccountStrategy, // invoked by user
		deleteAccountStrategy, // invoked by user
		startPolling,
		stopPolling,
		selectedAccountStrategy,
		setSelectedAccountStrategy,
	};
};

const store = createStore(useAccountStrategies);

export const { Provider } = store;
export default store.ctx;
