import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep } from 'lodash';
import authenticationCtx from 'context/authentication';
import useAlert from 'hooks/useAlert';
import { getBalances } from 'api/trading-api';
import { REFRESH_TIME_KRAKEN_MIN, EXCHANGES } from 'config/constants';

const REFRESH_TIME_KRAKEN_MS = REFRESH_TIME_KRAKEN_MIN * 60 * 1000;

const transformPhemexData = (type, record) => {
	// console.debug(type, record.balanceRq, Math.round(100000000 * record.balanceRq || 0), record);
	let balance;
	if (type === 'spot') {
		balance = BigInt(record.balanceEv);
	} else if (type === 'margin') {
		balance = BigInt(Math.round(100000000 * record.balanceRq));
	}
	if (type === 'contract') {
		balance = BigInt(record.accountBalanceEv - record.totalUsedBalanceEv);
		if (record.currency === 'USD') {
			balance = 10000n * balance;
		}
	}
	return {
		type,
		symbol: record.currency,
		balance,
		decimals: 8,
		exchange: 'phemex',
	};
};

const useBalances = () => {
	const alert = useAlert();
	const { isLoggedIn } = useContext(authenticationCtx);
	const [balances, setBalances] = useState([]);

	const timeoutId = useRef();

	const cancelTimeout = () => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}
	};

	const loadData = async () => {
		// todo: read balances
		return;
		try {
			const data = await getBalances({ exchange: EXCHANGES['gmx_arbitrum'] });
			data.forEach(token => {
				token.balance = BigInt(token.balance);
				token.exchange = 'gmx';
				token.network = 'arbitrum';
			});

			let dataPhemex = await getBalances({ exchange: EXCHANGES['phemex'] });
			const dataPhemexSpot = dataPhemex.spot.map(transformPhemexData.bind(null, 'spot'));
			const dataPhemexMargin = dataPhemex.margin.map(transformPhemexData.bind(null, 'margin'));
			const dataPhemexContract = dataPhemex.contract.map(transformPhemexData.bind(null, 'contract'));

			Array.prototype.push.apply(data, dataPhemexSpot);
			Array.prototype.push.apply(data, dataPhemexMargin);
			Array.prototype.push.apply(data, dataPhemexContract);

			await setBalances(cloneDeep(data));
		} catch (err) {
			console.error(err);
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
		timeoutId.current = setTimeout(loadData, REFRESH_TIME_KRAKEN_MS);
	};

	const refreshBalances = () => {
		cancelTimeout();
		return loadData();
	};

	useEffect(() => {
		if (isLoggedIn) {
			loadData();
		}

		return () => {
			cancelTimeout();
		};
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			cancelTimeout();
			loadData();
		} else {
			setBalances([]);
		}
	}, [isLoggedIn]);

	return {
		balances,
		refreshBalances,
	};
};

const store = createStore(useBalances);

export const { Provider } = store;
export default store.ctx;
