import { useState } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep, values } from 'lodash';
import { DEFAULT_EXCHANGE, EXCHANGES } from 'config/constants';

const EXCHANGE_ARRAYS = values(EXCHANGES);

const useExchange = () => {
	const [exchange, setExchangeObject] = useState(DEFAULT_EXCHANGE && cloneDeep(DEFAULT_EXCHANGE));
	// always lowercase
	const setExchange = name => {
		const newExchangeObject = EXCHANGE_ARRAYS.find(
			exchange => exchange.displayName.toLowerCase() === name.toLowerCase(),
		);
		if (newExchangeObject) {
			setExchangeObject(cloneDeep(newExchangeObject));
		}
	};

	return {
		exchange,
		setExchange,
	};
};

const store = createStore(useExchange);

export const { Provider } = store;
export default store.ctx;
