import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Seo from 'components/common/Seo';
import Header from 'components/section/Header';
import LoginForm from 'components/common/LoginForm';
import GlobalContext from 'components/layout/GlobalContext';
import useHasMounted from 'hooks/useHasMounted';
import authenticationCtx from 'context/authentication';
import darkmodeCtx from 'context/darkmode'; // in this stage, it is not yet bacome part of the ContextProvider
import feedbackCtx from 'context/feedback';
import backdropCtx from 'context/backdrop';

const Layout = props => {
	return (
		<GlobalContext pageProps={props}>
			<LayoutInner {...props} />
		</GlobalContext>
	);
};

const LayoutInner = ({ children, ...props }) => {
	const hasMounted = useHasMounted();
	const { initialized, isLoggedIn } = useContext(authenticationCtx);
	const { darkmode } = useContext(darkmodeCtx);
	const { feedbackMsg, feedbackHasBackdrop } = useContext(feedbackCtx);
	const { backdrop } = useContext(backdropCtx);

	const pageTitle = 'ITSA Trading App';
	const pageDescription = 'ITSA Trading App';

	let pageContent = <LoginForm {...props} />; // in case a non admin tries to access an adminpage directly by its url:

	if (!initialized) {
		return <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-lg">Loading...</div>;
	}

	if (isLoggedIn) {
		pageContent = children;
	}

	let backdropLayer;
	if (backdrop) {
		backdropLayer = <div className="fixed inset-0 bg-black opacity-50 z-40"></div>;
	}

	let feedbackLayer;
	if (feedbackMsg) {
		let feedbackBackdrop;
		if (feedbackHasBackdrop) {
			feedbackBackdrop = <div className="fixed inset-0 bg-black opacity-50 z-40"></div>;
		}
		feedbackLayer = (
			<>
				{feedbackBackdrop}
				<div className="fixed inset-0 z-50">{feedbackMsg}</div>
			</>
		);
	}

	return (
		<div className="flex min-h-screen flex-col gap-y-16">
			{backdropLayer}
			{feedbackLayer}
			<Seo title={pageTitle} description={pageDescription} />
			<Header {...props} />
			<main className="container flex grow flex-col gap-y-16">
				{pageContent}
				{feedbackMsg}
			</main>
		</div>
	);
};

Layout.defaultProps = {
	pageContext: {},
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	pageContext: PropTypes.object,
};

LayoutInner.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
