import { useEffect, useState, useRef } from 'react';
import createStore from 'ctx-provider';
import { isEqual, cloneDeep } from 'lodash';
import { later } from 'lib/timers';
import { getStrategies } from 'api/trading-api';
import { getTradesResultScore, getStrategyMaturity } from 'lib/strategy-trades-result-score';
import { REFRESH_TIME_STRATEGIES_SEC } from 'config/constants';

const REFRESH_TIME_STRATEGIES_SEC_MS = REFRESH_TIME_STRATEGIES_SEC * 1000;

const useStrategies = () => {
	const [strategies, setStrategies] = useState([]);
	const [activeStrategies, setActiveStrategies] = useState([]);
	const strategiesRef = useRef([]);
	const timeoutId = useRef();

	const startPolling = () => {
		stopPolling();
		timeoutId.current = later(refresh, 0, REFRESH_TIME_STRATEGIES_SEC_MS);
	};

	const stopPolling = () => {
		if (timeoutId.current) {
			timeoutId.current.cancel();
		}
	};

	useEffect(() => {
		return () => {
			stopPolling();
		};
	}, []);

	const refresh = async () => {
		try {
			const newStrategies = await getStrategies({ showperformance: true, performancelimit: 20 });

			newStrategies?.forEach(strategy => {
				if (!strategy.strategyenter) {
					strategy.strategyenter = {
						name: 'group',
					};
				}
				if (!strategy.strategyexit) {
					strategy.strategyexit = {
						name: 'group',
					};
				}
				// calculate the tradesresultscore
				strategy.tradesresultscore = getTradesResultScore(strategy.tradesresult);
				strategy.maturity = getStrategyMaturity(strategy.tradesresult);
			});
			if (!isEqual(newStrategies, strategiesRef.current)) {
				strategiesRef.current = cloneDeep(newStrategies);
				setStrategies(newStrategies);
				setActiveStrategies(newStrategies.filter(strategy => !!strategy.active));
			}
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	return {
		strategies,
		activeStrategies,
		refresh,
		startPolling,
		stopPolling,
	};
};

const store = createStore(useStrategies);

export const { Provider } = store;
export default store.ctx;
