import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep } from 'lodash';
import authenticationCtx from 'context/authentication';
import useAlert from 'hooks/useAlert';
import {
	marginLong as apiMarginLong,
	marginShort as apiMarginShort,
	closeMarginLong as apiCloseMarginLong,
	closeMarginShort as apiCloseMarginShort,
	canCancelOrder as apiCanCancelOrder,
	cancelOrder as apiCancelOrder,
	getPositions as apiGetPositions,
	getStops as apiGetStops,
	setStopLossLong as apiSetStopLossLong,
	setStopLossShort as apiSetStopLossShort,
	setTakeProfitLong as apiSetTakeProfitLong,
	setTakeProfitShort as apiSetTakeProfitShort,
	getNewTradeStats as apiGetNewTradeStats,
	swapTokens as apiSwapTokens,
} from 'api/trading-api';
import { REFRESH_TIME_OPENORDERS_SECS, TRADE_POINTS, ARBITRUM, PHEMEX, USD_DECIMALS } from 'config/constants';
import CONTRACTS from 'config/contracts';
import exchangeCtx from 'context/exchange';
import bnReviver from 'lib/bn-reviver';
import expandBnDecimals from 'lib/expand-BN-decimals';

const REFRESH_TIME_OPENORDERS_MS = REFRESH_TIME_OPENORDERS_SECS * 1000;

const sortFn = (a, b) => {
	const aSymbol = a.currency;
	const bSymbol = b.currency;
	if (aSymbol === 'USDT' && bSymbol !== 'USDT') {
		return -1;
	}
	if (aSymbol !== 'USDT' && bSymbol === 'USDT') {
		return 1;
	}
	if (aSymbol < bSymbol) {
		return -1;
	}
	if (aSymbol > bSymbol) {
		return 1;
	}
	return 0;
};

const mapGmxPosition = position => {
	const precision = TRADE_POINTS[ARBITRUM].PRECISION;
	const LEVARAGE_PRECISION = TRADE_POINTS[ARBITRUM].BASIS_POINTS_DIVISOR_BN;
	const totalFee = position.totalFees + position.closingFee;
	const key = position.key;
	const symbol = position.indexToken.symbol;
	const leverage = position.leverage;
	const leverageStr = position.leverageStr;
	const isLong = position.isLong;

	// const paid = position.collateral + totalFee;
	const paid = position.collateral;

	const paidUsd = expandBnDecimals(paid, USD_DECIMALS) / precision;
	const feesUsd = expandBnDecimals(totalFee, USD_DECIMALS) / precision;

	const positionStart = (position.collateralAfterFee * leverage) / LEVARAGE_PRECISION;
	const positionStartUsd = expandBnDecimals(positionStart, USD_DECIMALS) / precision;
	const borrowed = position.borrowed;
	const borrowedUsd = expandBnDecimals(borrowed, USD_DECIMALS) / precision;

	const currentPosition = (position.netValue * leverage) / LEVARAGE_PRECISION;
	const currentPositionUsd = expandBnDecimals(currentPosition, USD_DECIMALS) / precision;
	const result = currentPosition - positionStart;
	const resultUsd = expandBnDecimals(result, USD_DECIMALS) / precision;
	const profit = (LEVARAGE_PRECISION * result) / leverage;
	const profitUsd = expandBnDecimals(profit, USD_DECIMALS) / precision;
	const profitPercent = parseFloat(((10000n * profit) / paid).toString()) / 100;

	// determine if we have profit, losses or zero result:
	const profitCents = profitUsd / expandBnDecimals(1, USD_DECIMALS - 2);
	const isZeroResult = profitCents === 0n;
	const hasProfit = !isZeroResult && profitUsd > 0n;
	const hasLosses = !isZeroResult && profitUsd < 0n;

	const entryPriceUsd = expandBnDecimals(position.averagePrice, USD_DECIMALS) / precision;
	const currentPriceUsd = expandBnDecimals(position.markPrice, USD_DECIMALS) / precision;
	const liquidationPriceUsd = expandBnDecimals(position.liquidationPrice, USD_DECIMALS) / precision;
	const breakEvenPriceUsd = expandBnDecimals(position.breakEvenPrice, USD_DECIMALS) / precision;

	let stopLossPriceUsd;
	if (position.stopPosition) {
		stopLossPriceUsd = expandBnDecimals(position.stopPosition.triggerPrice, USD_DECIMALS) / precision;
	} else {
		stopLossPriceUsd = 0n;
	}

	let takeProfitPriceUsd;
	if (position.takeProfitPosition) {
		takeProfitPriceUsd = expandBnDecimals(position.takeProfitPosition.triggerPrice, USD_DECIMALS) / precision;
	} else {
		takeProfitPriceUsd = 0n;
	}

	return {
		key,
		symbol,
		leverage,
		leverageStr,
		isLong,
		paidUsd,
		borrowedUsd,
		feesUsd,
		positionStartUsd,
		currentPositionUsd,
		resultUsd,
		profitUsd,
		profitPercent,
		breakEvenPriceUsd,
		stopLossPriceUsd,
		takeProfitPriceUsd,
		entryPriceUsd,
		currentPriceUsd,
		liquidationPriceUsd,
		hasProfit,
		hasLosses,
	};
};

const mapPhemexContractPosition = position => {
	return position;
	const precision = TRADE_POINTS[ARBITRUM].PRECISION;
	const LEVARAGE_PRECISION = TRADE_POINTS[ARBITRUM].BASIS_POINTS_DIVISOR_BN;
	const totalFee = position.totalFees + position.closingFee;
	const key = position.key;
	const symbol = position.indexToken.symbol;
	const leverage = position.leverage;
	const leverageStr = position.leverageStr;
	const isLong = position.isLong;

	// const paid = position.collateral + totalFee;
	const paid = position.collateral;

	const paidUsd = expandBnDecimals(paid, USD_DECIMALS) / precision;
	const feesUsd = expandBnDecimals(totalFee, USD_DECIMALS) / precision;

	const positionStart = (position.collateralAfterFee * leverage) / LEVARAGE_PRECISION;
	const positionStartUsd = expandBnDecimals(positionStart, USD_DECIMALS) / precision;
	const borrowed = position.borrowed;
	const borrowedUsd = expandBnDecimals(borrowed, USD_DECIMALS) / precision;

	const currentPosition = (position.netValue * leverage) / LEVARAGE_PRECISION;
	const currentPositionUsd = expandBnDecimals(currentPosition, USD_DECIMALS) / precision;
	const result = currentPosition - positionStart;
	const resultUsd = expandBnDecimals(result, USD_DECIMALS) / precision;
	const profit = (LEVARAGE_PRECISION * result) / leverage;
	const profitUsd = expandBnDecimals(profit, USD_DECIMALS) / precision;
	const profitPercent = parseFloat(((10000n * profit) / paid).toString()) / 100;

	// determine if we have profit, losses or zero result:
	const profitCents = profitUsd / expandBnDecimals(1, USD_DECIMALS - 2);
	const isZeroResult = profitCents === 0n;
	const hasProfit = !isZeroResult && profitUsd > 0n;
	const hasLosses = !isZeroResult && profitUsd < 0n;

	const entryPriceUsd = expandBnDecimals(position.averagePrice, USD_DECIMALS) / precision;
	const currentPriceUsd = expandBnDecimals(position.markPrice, USD_DECIMALS) / precision;
	const liquidationPriceUsd = expandBnDecimals(position.liquidationPrice, USD_DECIMALS) / precision;
	const breakEvenPriceUsd = expandBnDecimals(position.breakEvenPrice, USD_DECIMALS) / precision;

	let stopLossPriceUsd;
	if (position.stopPosition) {
		stopLossPriceUsd = expandBnDecimals(position.stopPosition.triggerPrice, USD_DECIMALS) / precision;
	} else {
		stopLossPriceUsd = 0n;
	}

	let takeProfitPriceUsd;
	if (position.takeProfitPosition) {
		takeProfitPriceUsd = expandBnDecimals(position.takeProfitPosition.triggerPrice, USD_DECIMALS) / precision;
	} else {
		takeProfitPriceUsd = 0n;
	}

	return {
		key,
		symbol,
		leverage,
		leverageStr,
		isLong,
		paidUsd,
		borrowedUsd,
		feesUsd,
		positionStartUsd,
		currentPositionUsd,
		resultUsd,
		profitUsd,
		profitPercent,
		breakEvenPriceUsd,
		stopLossPriceUsd,
		takeProfitPriceUsd,
		entryPriceUsd,
		currentPriceUsd,
		liquidationPriceUsd,
		hasProfit,
		hasLosses,
	};
};

const mapPhemexMarginPosition = position => {
	const precision = TRADE_POINTS[PHEMEX].PRECISION;
	const LEVARAGE_PRECISION = TRADE_POINTS[PHEMEX].BASIS_POINTS_DIVISOR_BN;
	const key = position.orderId;
	const symbol = position.baseCurrency;
	const leverage = 5n;
	const leverageStr = leverage.toString();
	const isLong = position.side === 'Buy';

	// const paid = expandBnDecimals(position.quoteQtyRq, USD_DECIMALS) / precision;
	const paid = 1n;
	const positionStart = expandBnDecimals(position.execQuoteQtyRq, USD_DECIMALS) / precision;
	const totalFee = paid - positionStart;

	const paidUsd = expandBnDecimals(paid, USD_DECIMALS) / precision;
	const feesUsd = expandBnDecimals(totalFee, USD_DECIMALS) / precision;

	const positionStartUsd = expandBnDecimals(positionStart, USD_DECIMALS) / precision;
	const borrowed = positionStart - positionStart / leverage;
	const borrowedUsd = expandBnDecimals(borrowed, USD_DECIMALS) / precision;

	// const currentPosition = (position.netValue * leverage) / LEVARAGE_PRECISION;
	const currentPosition = positionStart;

	const currentPositionUsd = expandBnDecimals(currentPosition, USD_DECIMALS) / precision;
	const result = currentPosition - positionStart;
	const resultUsd = expandBnDecimals(result, USD_DECIMALS) / precision;
	// const profit = (LEVARAGE_PRECISION * result) / leverage;
	const profit = 0n;
	const profitUsd = expandBnDecimals(profit, USD_DECIMALS) / precision;
	const profitPercent = parseFloat(((10000n * profit) / paid).toString()) / 100;

	// determine if we have profit, losses or zero result:
	const profitCents = profitUsd / expandBnDecimals(1, USD_DECIMALS - 2);
	const isZeroResult = profitCents === 0n;
	const hasProfit = !isZeroResult && profitUsd > 0n;
	const hasLosses = !isZeroResult && profitUsd < 0n;

	const entryPriceUsd = expandBnDecimals(position.execQuoteQtyRq, USD_DECIMALS) / precision;
	const currentPriceUsd = expandBnDecimals(position.execQuoteQtyRq, USD_DECIMALS) / precision;
	const liquidationPriceUsd = expandBnDecimals(position.execQuoteQtyRq, USD_DECIMALS) / precision;
	const breakEvenPriceUsd = expandBnDecimals(position.execQuoteQtyRq, USD_DECIMALS) / precision;

	// const entryPriceUsd = expandBnDecimals(position.averagePrice, USD_DECIMALS) / precision;
	// const currentPriceUsd = expandBnDecimals(position.markPrice, USD_DECIMALS) / precision;
	// const liquidationPriceUsd = expandBnDecimals(position.liquidationPrice, USD_DECIMALS) / precision;
	// const breakEvenPriceUsd = expandBnDecimals(position.breakEvenPrice, USD_DECIMALS) / precision;

	let stopLossPriceUsd;
	if (position.stopPosition) {
		stopLossPriceUsd = expandBnDecimals(position.stopPosition.triggerPrice, USD_DECIMALS) / precision;
	} else {
		stopLossPriceUsd = 0n;
	}

	let takeProfitPriceUsd;
	if (position.takeProfitPosition) {
		takeProfitPriceUsd = expandBnDecimals(position.takeProfitPosition.triggerPrice, USD_DECIMALS) / precision;
	} else {
		takeProfitPriceUsd = 0n;
	}

	return {
		key,
		symbol,
		leverage,
		leverageStr,
		isLong,
		paidUsd,
		borrowedUsd,
		feesUsd,
		positionStartUsd,
		currentPositionUsd,
		resultUsd,
		profitUsd,
		profitPercent,
		breakEvenPriceUsd,
		stopLossPriceUsd,
		takeProfitPriceUsd,
		entryPriceUsd,
		currentPriceUsd,
		liquidationPriceUsd,
		hasProfit,
		hasLosses,
	};
};

const useOrders = () => {
	const { exchange } = useContext(exchangeCtx);
	const alert = useAlert();
	const { isLoggedIn } = useContext(authenticationCtx);
	const [subscriptionActive, setSubscriptionActive] = useState(false);
	const [marginModelIndex, setMarginModelIndex] = useState(0);
	const [positions, setPositions] = useState([]);
	const [stopPositions, setStopPositions] = useState([]);
	const [debtRatio, setDebtRatio] = useState(0);
	const marginModelRef = useRef();
	const positionsRef = useRef({});
	const stopPositionsRef = useRef({});
	const timeoutId = useRef(exchange.marginModels[marginModelIndex]);

	useEffect(() => {
		marginModelRef.current = exchange.marginModels[marginModelIndex];
	}, [marginModelIndex]);

	const cancelTimeout = () => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}
	};

	const subscribe = () => {
		setSubscriptionActive(true);
	};

	const unsubscribe = () => {
		setSubscriptionActive(false);
	};

	const loadDataPhemex = async () => {
		try {
			const promises = await Promise.all([
				apiGetPositions({
					exchange: 'phemex',
				}),
				apiGetStops({
					exchange: 'phemex',
				}),
			]);
			console.debug('Phemex positions', promises[0]);
			let [phemexPositions, phemexStopPositions] = promises;

			const newPositions = cloneDeep(positionsRef.current);

			newPositions.phemex = {
				margin: phemexPositions.margin.map(mapPhemexMarginPosition),
				contract: phemexPositions.contract.map(mapPhemexContractPosition),
			};

			positionsRef.current = newPositions;
			setPositions(newPositions);

			const newStopPositions = cloneDeep(stopPositionsRef.current);
			newStopPositions.phemex = phemexStopPositions;
			stopPositionsRef.current = newStopPositions;
			setStopPositions(newStopPositions);
		} catch (err) {
			console.error(err);
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const loadDataKucoin = async () => {};

	const loadDataGmx = async () => {
		try {
			const promises = await Promise.all([
				apiGetPositions({
					exchange: 'gmx',
					network: 'arbitrum',
				}),
				apiGetStops({
					exchange: 'gmx',
					network: 'arbitrum',
				}),
			]);
			const nativeTokenAddress = CONTRACTS[ARBITRUM]['NATIVE_TOKEN'];
			let [gmxPositions, gmxStopPositions] = promises;

			// note that responses has been stringified by the API server and needs to be parsed using a reviver
			gmxPositions = JSON.parse(gmxPositions, bnReviver);
			gmxStopPositions = JSON.parse(gmxStopPositions, bnReviver);
			console.debug('GMX gmxPositions', gmxPositions);

			// now add the stoppositions:
			gmxStopPositions.forEach(stopPosition => {
				let positionRecord = gmxPositions.find(
					position =>
						(position.indexToken.address === stopPosition.indexToken ||
							(position.indexToken.isNative && stopPosition.indexToken === nativeTokenAddress)) &&
						stopPosition.type === 'Decrease' &&
						position.isLong === stopPosition.isLong &&
						((position.isLong && stopPosition.triggerPrice < position.markPrice) ||
							(!position.isLong && stopPosition.triggerPrice > position.markPrice)),
				);
				if (positionRecord) {
					positionRecord.stopPosition = stopPosition;
				}

				positionRecord = gmxPositions.find(
					position =>
						(position.indexToken.address === stopPosition.indexToken ||
							(position.indexToken.isNative && stopPosition.indexToken === nativeTokenAddress)) &&
						stopPosition.type === 'Decrease' &&
						position.isLong === stopPosition.isLong &&
						((position.isLong && stopPosition.triggerPrice > position.markPrice) ||
							(!position.isLong && stopPosition.triggerPrice < position.markPrice)),
				);
				if (positionRecord) {
					positionRecord.takeProfitPosition = stopPosition;
				}
			});

			gmxPositions = gmxPositions.map(mapGmxPosition);

			// gmxPositions.sort(sortFn);
			const newPositions = cloneDeep(positionsRef.current);
			newPositions.gmx = gmxPositions;
			positionsRef.current = newPositions;
			setPositions(newPositions);

			const newStopPositions = cloneDeep(stopPositionsRef.current);
			newStopPositions.gmx = gmxStopPositions;
			stopPositionsRef.current = newStopPositions;
			setStopPositions(newStopPositions);
		} catch (err) {
			console.error(err);
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const loadData = async () => {
		if (subscriptionActive) {
			console.debug('loaddata');
			cancelTimeout();
			await Promise.all([loadDataGmx(), loadDataPhemex(), loadDataKucoin()]);
			timeoutId.current = setTimeout(loadData, REFRESH_TIME_OPENORDERS_MS);
		}
	};

	useEffect(() => {
		return () => {
			cancelTimeout();
		};
	}, []);

	useEffect(() => {
		if ((subscriptionActive, isLoggedIn)) {
			loadData();
		} else {
			cancelTimeout();
			setPositions([]);
			setStopPositions([]);
		}
	}, [isLoggedIn, subscriptionActive]);

	const closeMarginLong = (config = {}) => {
		config = cloneDeep(config);
		// config.exchange = cloneDeep(exchange);
		// config.exchange.marginModel = config.exchange.marginModels[marginModelIndex];
		// console.debug(config);
		return apiCloseMarginLong(config);
	};

	const closeMarginShort = (config = {}) => {
		config = cloneDeep(config);
		// config.exchange = cloneDeep(exchange);
		// config.exchange.marginModel = config.exchange.marginModels[marginModelIndex];
		// console.debug(config);
		return apiCloseMarginShort(config);
	};

	const marginLong = (config = {}) => {
		config = cloneDeep(config);
		// config.exchange = cloneDeep(exchange);
		// config.exchange.marginModel = config.exchange.marginModels[marginModelIndex];
		if (typeof config.amountWholeUSDC === 'string') {
			config.amountWholeUSDC = parseInt(config.amountWholeUSDC);
		}
		if (typeof config.symbol === 'string') {
			config.symbol = config.symbol.toUpperCase();
		}
		if (typeof config.leverage === 'string') {
			config.leverage = parseFloat(config.leverage);
		}
		console.debug(config);
		return apiMarginLong(config);
	};

	const marginShort = (config = {}) => {
		config = cloneDeep(config);
		// config.exchange = cloneDeep(exchange);
		// config.exchange.marginModel = config.exchange.marginModels[marginModelIndex];
		if (typeof config.amountWholeUSDC === 'string') {
			config.amountWholeUSDC = parseInt(config.amountWholeUSDC);
		}
		if (typeof config.symbol === 'string') {
			config.symbol = config.symbol.toUpperCase();
		}
		if (typeof config.leverage === 'string') {
			config.leverage = parseFloat(config.leverage);
		}
		console.debug(config);
		return apiMarginShort(config);
	};

	const canCancelOrder = ({ exchange, network, isLong, symbol }) => {
		const action = isLong ? 'marginLong' : 'marginShort';
		return apiCanCancelOrder({ exchange, network, action, symbol });
	};

	const cancelOrder = ({ exchange, network, isLong, symbol }) => {
		const action = isLong ? 'marginLong' : 'marginShort';
		return apiCancelOrder({ exchange, network, action, symbol });
	};

	const canCancelClosePosition = ({ exchange, network, symbol, isLong }) => {
		const action = isLong ? 'closeMarginLong' : 'closeMarginShort';
		return apiCanCancelOrder({ exchange, network, action, symbol });
	};

	const cancelClosePosition = ({ exchange, network, symbol, isLong }) => {
		const action = isLong ? 'closeMarginLong' : 'closeMarginShort';
		return apiCancelOrder({ exchange, network, action, symbol });
	};

	const setStopLoss = ({ exchange, network, symbol, isLong, priceAvailable, stopPriceUsd }) => {
		const config = { exchange, network, stopPriceUsd, symbol, priceAvailable };
		if (isLong) {
			return apiSetStopLossLong(config);
		} else {
			return apiSetStopLossShort(config);
		}
	};

	const setTakeProfit = ({ exchange, network, symbol, isLong, priceAvailable, stopPriceUsd }) => {
		const config = { exchange, network, stopPriceUsd, symbol, priceAvailable };
		if (isLong) {
			return apiSetTakeProfitLong(config);
		} else {
			return apiSetTakeProfitShort(config);
		}
	};

	const getPositions = ({ exchange, network }) => {
		return apiGetPositions({ exchange, network });
	};
	const getStops = ({ exchange, network }) => {
		return apiGetStops({ exchange, network });
	};

	const refreshPositions = () => loadData();

	const swapTokens = async ({ exchange, network, fromSymbol, toSymbol, amountFloat }) => {
		return apiSwapTokens({ exchange, network, fromSymbol, toSymbol, amountFloat });
	};

	const getNewTradeStats = async ({ exchange, symbol, amountWholeUSDC, leverage, isLong, useCache }) => {
		return apiGetNewTradeStats({ exchange, symbol, amountWholeUSDC, leverage, isLong, useCache });
	};

	return {
		debtRatio,
		positions,
		stopPositions,
		setMarginModelIndex,
		marginModel: exchange.marginModels[marginModelIndex],
		marginLong,
		marginShort,
		closeMarginLong,
		closeMarginShort,
		canCancelOrder,
		cancelOrder,
		swapTokens,
		canCancelClosePosition,
		cancelClosePosition,
		refreshPositions,
		setTakeProfit,
		setStopLoss,
		getNewTradeStats,
		subscribe,
		unsubscribe,
	};
};

const store = createStore(useOrders);

export const { Provider } = store;
export default store.ctx;
