import React, { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { isEqual, cloneDeep } from 'lodash';
import {
	getStrategySimulations,
	getSimulatorStatus,
	defineStrategySimulation as defineStrategySimulationFn,
	getStrategySimulationDetails as getStrategySimulationDetailsFn,
	removeStrategySimulation as removeStrategySimulationFn,
	restartStrategySimulation as restartStrategySimulationFn,
	releaseSimulation as releaseSimulationFn,
} from 'api/trading-api';
import backdropCtx from 'context/backdrop';
import GlobalSimulationParams from 'components/common/modals/GlobalSimulationParams';
import { later } from 'lib/timers';
import { zoneDate } from 'lib/zone-date';
import { REFRESH_TIME_STRATEGYSIMULATIONS_SEC, REFRESH_TIME_STRATEGYSIMULATORS_SEC } from 'config/constants';

const REFRESH_TIME_STRATEGYSIMULATIONS_SEC_MS = REFRESH_TIME_STRATEGYSIMULATIONS_SEC * 1000;
const REFRESH_TIME_STRATEGYSIMULATORS_MS = REFRESH_TIME_STRATEGYSIMULATORS_SEC * 1000;

const sortFn = (a, b) => {
	// if (a.result.stoplossCount < b.result.stoplossCount) {
	// 	return -1;
	// }
	// if (a.result.stoplossCount > b.result.stoplossCount) {
	// 	return 1;
	// }
	// if (a.result.badTrades < b.result.badTrades) {
	// 	return -1;
	// }
	// if (a.result.badTrades > b.result.badTrades) {
	// 	return 1;
	// }

	const totalProfitA = Math.round(a.result.totalProfit * 100);
	const totalProfitB = Math.round(b.result.totalProfit * 100);
	if (totalProfitA < totalProfitB) {
		return 1;
	}
	if (totalProfitA > totalProfitB) {
		return -1;
	}

	// on equal totalprofit, we want those with the less goodtrades on top, because they have the best profit per trade
	if (a.result.goodTrades < b.result.goodTrades) {
		return -1;
	}
	if (a.result.goodTrades > b.result.goodTrades) {
		return 1;
	}
	return 0;
};

const sortFnMacdFinder = (a, b) => {
	if (a.result.percentageGood > b.result.percentageGood) {
		return -1;
	}
	if (a.result.percentageGood < b.result.percentageGood) {
		return 1;
	}

	if (a.result.stoplossCount < b.result.stoplossCount) {
		return -1;
	}
	if (a.result.stoplossCount > b.result.stoplossCount) {
		return 1;
	}
	if (a.result.badTrades < b.result.badTrades) {
		return -1;
	}
	if (a.result.badTrades > b.result.badTrades) {
		return 1;
	}

	const totalProfitA = Math.round(a.result.totalProfit * 100);
	const totalProfitB = Math.round(b.result.totalProfit * 100);
	if (totalProfitA < totalProfitB) {
		return 1;
	}
	if (totalProfitA > totalProfitB) {
		return -1;
	}

	// on equal totalprofit, we want those with the less goodtrades on top, because they have the best profit per trade
	if (a.result.goodTrades < b.result.goodTrades) {
		return -1;
	}
	if (a.result.goodTrades > b.result.goodTrades) {
		return 1;
	}
	return 0;
};

const sortFnSimulators = (a, b) => {
	const aSystemName = (a.systemname || '').toUpperCase();
	const bSystemName = (b.systemname || '').toUpperCase();

	if (aSystemName < bSystemName) {
		return -1;
	}
	if (aSystemName > bSystemName) {
		return 1;
	}
	return 0;
};

const simulatorStatusMapFn = item => {
	if (item.updatetime) {
		const now = new Date();
		item.updatetime = zoneDate(item.updatetime);
		const updatetime = new Date(item.updatetime);

		// const now = zoneDate();
		const iddleSeconds = (now.getTime() - updatetime.getTime()) / 1000;
		if (iddleSeconds <= 0) {
			item.iddletime = `00:00:00`;
		} else {
			const hours = Math.floor(iddleSeconds / 3600);
			const minutes = Math.floor((iddleSeconds % 3600) / 60);
			const seconds = Math.round(iddleSeconds % 60);
			const formattedHours = String(hours).padStart(2, '0');
			const formattedMinutes = String(minutes).padStart(2, '0');
			const formattedSeconds = String(seconds).padStart(2, '0');
			item.iddletime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		}
		item.iddletimeSeconds = iddleSeconds;
	}
	if (item.processtime) {
		const hours = item.processtime?.hours || 0;
		const minutes = (item.processtime?.minutes || 0) + 60 * hours;
		const seconds = item.processtime?.seconds || 0;
		const formattedMinutes = String(minutes).padStart(2, '0');
		const formattedSeconds = String(seconds).padStart(2, '0');
		item.processtime = `${formattedMinutes}:${formattedSeconds}`;
	}
	item.starttime = zoneDate(item.starttime);
	// item.updatetime = zoneDate(item.updatetime);
	item.eta = zoneDate(item.eta);
	if (!item.processedby) {
		item.systemname = 'NOT ASSIGNED';
	}
};

const useStrategySimulations = () => {
	const { setBackdrop } = useContext(backdropCtx);
	const [strategySimulations, setStrategySimulations] = useState([]);
	const [simulatorStatus, setSimulatorStatus] = useState([]);
	const [createStrategySimulationForm, setCreateStrategySimulationForm] = useState(false);
	const strategySimulationsRef = useRef([]);
	const simulatorStatusRef = useRef([]);
	const timeoutId = useRef();
	const timeoutId2 = useRef();

	const startPolling = () => {
		stopPolling();
		timeoutId.current = later(loadData, 0, REFRESH_TIME_STRATEGYSIMULATIONS_SEC_MS);
	};

	const stopPolling = () => {
		if (timeoutId.current) {
			timeoutId.current.cancel();
		}
	};

	const startPollingSimulatorStatus = () => {
		stopPollingSimulatorStatus();
		timeoutId2.current = later(loadDataSimulatorStatus, 0, REFRESH_TIME_STRATEGYSIMULATORS_MS);
	};

	const stopPollingSimulatorStatus = () => {
		if (timeoutId2.current) {
			timeoutId2.current.cancel();
		}
	};

	useEffect(() => {
		return () => {
			stopPolling();
			stopPollingSimulatorStatus();
		};
	}, []);

	const loadData = async () => {
		try {
			const newStrategySimulations = await getStrategySimulations();
			if (!isEqual(newStrategySimulations, strategySimulationsRef.current)) {
				strategySimulationsRef.current = cloneDeep(newStrategySimulations);
				setStrategySimulations(newStrategySimulations);
			}
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const loadDataSimulatorStatus = async () => {
		try {
			const newSimulatorStatus = await getSimulatorStatus();
			newSimulatorStatus.forEach(simulatorStatusMapFn);
			newSimulatorStatus.sort(sortFnSimulators);
			if (!isEqual(newSimulatorStatus, simulatorStatusRef.current)) {
				simulatorStatusRef.current = cloneDeep(newSimulatorStatus);
				setSimulatorStatus(newSimulatorStatus);
			}
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
	};

	const refresh = () => {
		loadData();
	};

	const refreshSimulatorStatus = () => {
		loadDataSimulatorStatus();
	};

	const getStrategySimulationDetails = async ({ simulationid, limit, isMacdFinder }) => {
		let strategyDetails;
		try {
			limit = null;

			strategyDetails = await getStrategySimulationDetailsFn({ strategysimulationid: simulationid, limit });
			if (!strategyDetails.data) {
				strategyDetails.data = [];
			}
			strategyDetails.data.forEach(item => {
				item.result.stoplossCount = item.result.stoplossCount || 0;
				item.result.goodTrades = item.result.goodTrades || 0;
				item.result.badTrades = item.result.badTrades || 0;
				item.result.percentageGood =
					item.result.badTrades === 0
						? item.result.goodTrades === 0
							? 0
							: 100
						: (100 * item.result.goodTrades) / (item.result.goodTrades + item.result.badTrades);
			});

			strategyDetails.data = strategyDetails.data.filter(
				item => item.result.goodTrades > 0 || item.result.badTrades > 0,
			);

			strategyDetails.data.sort(isMacdFinder ? sortFnMacdFinder : sortFn);
		} catch (err) {
			const message = err.response?.data?.error || err.message || 'Server error';
			// send an alert, but prevent alerting multiple times, by marking this message with "match":
			alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
		}
		return strategyDetails;
	};

	const removeStrategySimulation = async simulationid => removeStrategySimulationFn({ simulationid });

	const restartStrategySimulation = async simulationid => restartStrategySimulationFn({ simulationid });

	const releaseSimulation = async ({ simulationid, processedby, force }) =>
		releaseSimulationFn({ simulationid, processedby, force });

	const defineStrategySimulation = async ({ strategyid, simulate, stoploss }) =>
		defineStrategySimulationFn({ strategyid, simulate, stoploss });

	const handleCloseForm = () => {
		setCreateStrategySimulationFormVisible(false);
	};

	const handleStartSimulation = (strategyid, enterIndicator, indicatorIndex, data, stoploss) => {
		const keys = Object.keys(data);
		if (keys.length !== 0) {
			setCreateStrategySimulationFormVisible(false);

			// transform 'heikinashi' into candleType
			if (data.heikinashi) {
				data.candleType = {
					values: ['regular', 'heikinashi'],
					type: 'list',
				};
				delete data.heikinashi;
			}

			const simulate = {
				indicatorconfig: data,
				strategyenter: null,
				strategyexit: null,
				// entersecurities: {},
				// exitsecurities: {},
			};

			// if (typeof indicatorIndex === 'number') {
			// 	if (enterIndicator) {
			// 		simulate.strategyenter = indicatorIndex;
			// 	} else {
			// 		simulate.strategyexit = indicatorIndex;
			// 	}
			// }
			if (enterIndicator) {
				simulate.strategyenter = indicatorIndex;
			} else {
				simulate.strategyexit = indicatorIndex;
			}

			const params = { strategyid, simulate };
			if (!data.stoploss && stoploss !== undefined) {
				params.stoploss = stoploss;
			}
			defineStrategySimulation(params);
		}
	};

	const setCreateStrategySimulationFormVisible = value => {
		setBackdrop(!!value);

		if (value) {
			const { strategyid, item, i, stoploss } = value;
			const { enterIndicator } = item;
			const formElementContent = item.formSimulationElement({
				onClose: handleCloseForm,
				onStartSimulation: handleStartSimulation.bind(null, strategyid, enterIndicator, i),
				stoploss,
			});
			const createSimulationLayer = (
				<div className="fixed left-1/2 -translate-x-1/2 text-lg inset-0 z-50 bg-black-30">{formElementContent}</div>
			);

			setCreateStrategySimulationForm(createSimulationLayer);
		} else {
			setCreateStrategySimulationForm(null);
		}
	};

	const setCreateGlobalStrategySimulationFormVisible = ({ visible, strategyid, stoploss } = {}) => {
		setBackdrop(!!visible);

		if (visible) {
			const globalSimulationForm = (
				<GlobalSimulationParams
					onClose={handleCloseForm}
					onStartSimulation={handleStartSimulation.bind(null, strategyid, null, null)}
					stoploss={stoploss}
				/>
			);
			setCreateStrategySimulationForm(globalSimulationForm);
		} else {
			setCreateStrategySimulationForm(null);
		}
	};

	return {
		strategySimulations,
		simulatorStatus,
		getStrategySimulationDetails,
		removeStrategySimulation,
		restartStrategySimulation,
		releaseSimulation,
		defineStrategySimulation,
		setCreateStrategySimulationFormVisible,
		setCreateGlobalStrategySimulationFormVisible,
		createStrategySimulationForm,
		startPolling,
		stopPolling,
		startPollingSimulatorStatus,
		stopPollingSimulatorStatus,
		refresh,
		refreshSimulatorStatus,
	};
};

const store = createStore(useStrategySimulations);

export const { Provider } = store;
export default store.ctx;
