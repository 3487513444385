exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-analyse-jsx": () => import("./../../../src/pages/analyse.jsx" /* webpackChunkName: "component---src-pages-analyse-jsx" */),
  "component---src-pages-balance-jsx": () => import("./../../../src/pages/balance.jsx" /* webpackChunkName: "component---src-pages-balance-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-password-jsx": () => import("./../../../src/pages/password.jsx" /* webpackChunkName: "component---src-pages-password-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-settings-jsx": () => import("./../../../src/pages/settings.jsx" /* webpackChunkName: "component---src-pages-settings-jsx" */),
  "component---src-pages-simulators-jsx": () => import("./../../../src/pages/simulators.jsx" /* webpackChunkName: "component---src-pages-simulators-jsx" */),
  "component---src-pages-strategy-jsx": () => import("./../../../src/pages/strategy.jsx" /* webpackChunkName: "component---src-pages-strategy-jsx" */),
  "component---src-pages-trade-history-jsx": () => import("./../../../src/pages/trade-history.jsx" /* webpackChunkName: "component---src-pages-trade-history-jsx" */),
  "component---src-pages-trading-config-edit-jsx": () => import("./../../../src/pages/trading-config/edit.jsx" /* webpackChunkName: "component---src-pages-trading-config-edit-jsx" */),
  "component---src-pages-trading-config-new-jsx": () => import("./../../../src/pages/trading-config/new.jsx" /* webpackChunkName: "component---src-pages-trading-config-new-jsx" */),
  "component---src-pages-trading-configuration-jsx": () => import("./../../../src/pages/trading-configuration.jsx" /* webpackChunkName: "component---src-pages-trading-configuration-jsx" */),
  "component---src-pages-trading-jsx": () => import("./../../../src/pages/trading.jsx" /* webpackChunkName: "component---src-pages-trading-jsx" */),
  "component---src-pages-user-management-jsx": () => import("./../../../src/pages/user-management.jsx" /* webpackChunkName: "component---src-pages-user-management-jsx" */)
}

