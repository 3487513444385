const NOT_SO_GOOD = 0.85;
const BAD = 0.7;
const SUPERBAD = 0.6;
const MATURE = 3;
const SUPERMATURE = 8;
const MAX_INSPECTED_ITEMS = 30; // to prevent the calculation of "bad" to have the same impact on a huge nr of items as on a small nr

const _getTradesResultScore = tradesresult => {
	if (!tradesresult) {
		return 1;
	}

	let good = 0;
	let bad = 0;
	tradesresult.some((result, i) => {
		const profitable = result.profitable;
		if (profitable) {
			good++;
		} else {
			bad++;
		}
		return i === MAX_INSPECTED_ITEMS - 1;
	});

	const ratio = good / tradesresult.length;
	let score;

	if (ratio <= SUPERBAD) {
		score = 0; // red
	} else if (ratio <= BAD) {
		score = 1; // orange
	} else if (ratio <= NOT_SO_GOOD) {
		score = 2; // yellow
	} else {
		score = 3; // green
	}

	return { score, good, bad };
};

const getTradesResultScore = tradesresult => {
	const strategyMaturity = getStrategyMaturity(tradesresult);
	const substract = 3 - strategyMaturity;
	let { score } = _getTradesResultScore(tradesresult);

	score = score - substract;

	return Math.max(score, 0);
};

const getStrategyMaturity = tradesresult => {
	if (!tradesresult || tradesresult.length < MATURE) {
		return 1;
	}
	if (tradesresult.length < SUPERMATURE) {
		return 2;
	}
	return 3;
};

const getInfo = tradesresult => {
	const { score, good, bad } = _getTradesResultScore(tradesresult);
	const maturity = getStrategyMaturity(tradesresult);
	const finalScore = Math.max(0, score + maturity - 3);
	const COLORS = {
		0: 'rgb(239, 68, 68)',
		1: 'rgb(217, 119, 6)',
		2: 'rgb(253, 224, 71)',
		3: 'rgb(22, 163, 74)',
	};
	const TEXT_COLORS = {
		0: 'white',
		1: 'white',
		2: 'black',
		3: 'white',
	};
	const COLORS_BETA = {
		1: 'rgb(217, 119, 6)',
		2: 'rgb(253, 224, 71)',
	};
	const TEXT_COLORS_BETA = {
		1: 'white',
		2: 'black',
	};
	const scoreColorStyle = `display: flex; align-items: center; justify-content: center; border-width: 1px; background-color: ${COLORS[finalScore]}; color: ${TEXT_COLORS[finalScore]}; border-radius: 9999px; width: 1.25rem; height: 1.25rem; text-align: center; font-weight: 300; font-size: 0.75rem; line-height: 1.2rem`;

	let beta = '';
	if (maturity < SUPERMATURE) {
		const betaStyle = `display: inline-flex; padding-left: 6px; padding-right: 6px; margin-left: 12px; align-items: center; justify-content: center; border-width: 1px; background-color: ${COLORS_BETA[maturity]}; color: ${TEXT_COLORS_BETA[maturity]}; border-radius: 1rem; rounded-full; height: 1.25rem; text-align: center; font-weight: 300; font-size: 0.75rem; line-height: 1.2rem`;
		beta = `<span style="${betaStyle}">beta</span>`;
	}

	return (
		`<span style="color: yellow; font-size: 120%"><u>Strategy Score</u>: <div style="display: inline-block"><div style="${scoreColorStyle}">${finalScore}</div></div></span><br><br>` +
		'<u>Colors</u><br>' +
		`<div style="display: inline-block; background-color: ${COLORS[0]}; padding: 4px; border-radius: 10%">0: red</div><br>` +
		`<div style="display: inline-block; background-color: ${COLORS[1]}; padding: 4px; border-radius: 10%">1: orange</div><br>` +
		`<div style="display: inline-block; background-color: ${COLORS[2]}; padding: 4px; border-radius: 10%; color: black">2: yellow</div><br>` +
		`<div style="display: inline-block; background-color: ${COLORS[3]}; padding: 4px; border-radius: 10%">3: green</div><br><br>` +
		'<u>Trade Result</u><br>' +
		`Good trades: ${good}<br>` +
		`Bad trades: ${bad}<br>` +
		`Score: <span style="color: yellow; font-weight: bold">${score}</span> out of 3<br><br>` +
		'<u>Strategy Maturity</u><br>' +
		`Number of trades: ${tradesresult.length}<br>` +
		`Score: ${maturity} out of 3<br>` +
		`Maturity_Weakness: <span style="color: yellow; font-weight: bold">${3 - maturity}</span>${beta}<br>` +
		`(Maturity_Weakness = 3 - Score)<br><br><br>` +
		'<u>Explanation</u><br><br>' +
		'Strategy Score = Trade_Result - Maturity_Weakness<br>' +
		`=><br>` +
		`<span style="color: yellow; font-weight: bold">${score} - ${3 - maturity} = ${finalScore}</span><br><br>` +
		`Trade result over the last ${MAX_INSPECTED_ITEMS} trades:<br>` +
		`Bad Trades >= ${SUPERBAD} --> score = 1<br>` +
		`Bad Trades >= ${BAD} --> score = 2<br>` +
		`Bad Trades < ${BAD} --> score = 3<br><br>` +
		`Number of Trades:<br>` +
		`Trades < ${MATURE} --> score = 1<br>` +
		`Trades < ${SUPERMATURE} --> score = 2<br>` +
		`Trades >= ${SUPERMATURE} --> score = 3`
	);
};

module.exports = {
	getTradesResultScore,
	getStrategyMaturity,
	getInfo,
};
