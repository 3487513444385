const isFloat = value => typeof value === 'number' && Math.round(value) !== value;

const expandBnDecimals = (anyValue, decimals) => {
	let numberBN;
	if (isFloat(anyValue)) {
		anyValue = anyValue.toString();
	}
	if (typeof anyValue === 'string') {
		anyValue = anyValue.replaceAll(',', '.');
		if (anyValue.indexOf('.') !== -1) {
			numberBN = BigInt(Math.round(parseFloat(anyValue.replaceAll(',', '.')) * 10 ** decimals));
		}
	}
	if (numberBN === undefined) {
		numberBN = BigInt(anyValue) * 10n ** BigInt(decimals);
	}
	return numberBN;
};

export default expandBnDecimals;
