import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import authenticationCtx from 'context/authentication';
import darkmodeCtx from 'context/darkmode';
import { Link, navigate } from 'gatsby';
import useClickOutside from 'hooks/useClickOutside';
import { headerNavItems, userNavItems, ENVIRONMENT } from 'config/constants';
import useHasMounted from 'hooks/useHasMounted';
import Logo from 'assets/images/svg/logo.inline.svg';

const Header = props => {
	const { darkmode, toggleDarkmode } = useContext(darkmodeCtx);
	const hasMounted = useHasMounted();
	const [open, setOpen] = useState(false);
	const [openUserManagement, setOpenUserManagement] = useState(false);
	const [showUserToolTip, setShowUserToolTip] = useState(false);
	const { isLoggedIn, logout, scope } = useContext(authenticationCtx);
	const userMenuRef = useRef(null);
	let developmentLabel;

	const navigateTo = url => {
		setOpen(false);
		setOpenUserManagement(false);
		navigate(url);
	};

	const handleLogout = () => {
		setOpenUserManagement(false);
		navigate('/');
		logout();
	};

	const handleOpenNavMenu = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setOpen(!!isOpen);
	};

	const handleOpenUserMenu = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setShowUserToolTip(false);
		setOpenUserManagement(!!isOpen);
	};

	useClickOutside([userMenuRef], handleOpenUserMenu);

	if (!hasMounted) {
		return null;
	}

	if (ENVIRONMENT === 'development') {
		developmentLabel = <div className="ml-auto">Development</div>;
	}

	const logo = (
		<Link to="/" className="my-auto">
			<Logo className="h-10" />
		</Link>
	);

	let menuItems;
	let menuItemsUser;
	let avatarOrLogin = (
		<button type="button" className="bg-white rounded-full px-6 py-1 text-black" onClick={() => navigateTo('/balance')}>
			Login
		</button>
	);

	if (isLoggedIn) {
		menuItems = headerNavItems.map(({ label, url }) => (
			<Link
				to={url}
				className="bg-white rounded-full px-6 py-1 text-black"
				activeClassName="!bg-indigo-500 text-white"
				key={url}
			>
				{label}
			</Link>
		));

		menuItemsUser = userNavItems.map(({ label, url, scope }) => (
			<Link to={url} className="" key={url}>
				{label}
			</Link>
		));

		const fullUserName = '';
		menuItemsUser.push(
			<button type="button" className="mr-auto" onClick={handleLogout} key="logout">
				Logout
			</button>,
		);
		avatarOrLogin = (
			<div className="relative" ref={userMenuRef}>
				<button
					type="button"
					className="border border-white p-1 rounded"
					onClick={e => handleOpenUserMenu(e, !openUserManagement)}
				>
					<img alt={fullUserName} src="/images/avatar.png" width="40" height="40" />
				</button>

				<div
					onClick={e => handleOpenUserMenu(e, false)}
					className={clsx(
						'flex flex-col mt-3 shadow-xl rounded space-y-3 absolute z-20 bg-white text-black right-0 p-4 whitespace-nowrap',
						{
							'invisible opacity-0': !openUserManagement,
							'visible opacity-100': openUserManagement,
						},
					)}
				>
					{menuItemsUser}
				</div>
			</div>
		);
	}

	menuItems = <nav className="flex space-x-4">{menuItems}</nav>;

	const hamburgerMenu = (
		<>
			<button onClick={event => handleOpenNavMenu(event, true)}>Menu</button>
			{menuItems}
		</>
	);

	const handleCloseUserToolTip = () => {
		setShowUserToolTip(false);
	};

	const handleOpenUserToolTip = () => {
		if (!openUserManagement) {
			setShowUserToolTip(true);
		}
	};

	return (
		<header className="container-fluid py-2 bg-black-42 border-b border-white">
			<div className="container flex items-center gap-4">
				{logo}
				{menuItems}
				{developmentLabel}
				{avatarOrLogin}
			</div>
		</header>
	);
};

Header.defaultProps = {};

Header.propTypes = {
	uri: PropTypes.string.isRequired,
};

export default Header;
